import { Link } from "react-router-dom";
import "./Confidentiality.css";

function ConfidentialityIdeaList() {
  return (
    <div id="confidentiality">
      <Link to="/idealist">
        <span className="back">Back to home</span>
      </Link>
      <h1>IdeaList Privacy Policy</h1>
      <br />
      <br />
      <h2>Introduction</h2>
      <br />
      <br />
      The privacy of our users is of utmost importance to us. This privacy
      policy describes IdeaList's practices regarding the collection, use, and
      protection of the personal data of our application's users.
      <br />
      <br />
      <h2>Data Collection and Usage</h2>
      <br />
      <br />
      IdeaList integrates Google AdMob which collects data as follows:
      <ul>
        <li>
          <strong>Course Location:</strong> Used for Third-Party Advertising,
          Analytics; Linked to user; Tracking enabled.
        </li>
        <li>
          <strong>Device ID:</strong> Used for Third-Party Advertising,
          Analytics; Linked to user; Tracking enabled.
        </li>
        <li>
          <strong>Product Interaction:</strong> Used for Third-Party
          Advertising, Analytics; Linked to user; Tracking enabled.
        </li>
        <li>
          <strong>Advertising Data:</strong> Used for Third-Party Advertising,
          Analytics; Linked to user; Tracking enabled.
        </li>
        <li>
          <strong>Crash Data:</strong> Used for Analytics; Not linked to user;
          Tracking enabled.
        </li>
        <li>
          <strong>Performance Data:</strong> Used for Third-Party Advertising,
          Analytics; Linked to user; Tracking enabled.
        </li>
      </ul>
      <br />
      <br />
      <h2>In-App Purchases</h2>
      <br />
      <br />
      If IdeaList offers in-app purchases, the transactions are processed by the
      platform provider (e.g., Apple's App Store). We do not process, store, or
      have access to any payment information related to in-app purchases. For
      questions or concerns about in-app purchase transactions, please contact
      the platform provider directly.
      <br />
      <br />
      <h2>External connection</h2>
      <br />
      <br />
      IdeaList synchronizes your data with iCloud; therefore, no user data is
      shared with other services, partners, or third parties. The server and
      available data are hosted by Apple.
      <br />
      <br />
      <h2>Data security</h2>
      <br />
      <br />
      The data security of our users is essential. IdeaList uses appropriate
      security measures to protect the data stored locally on the user's device.
      We recommend users to take additional precautions to protect their data,
      such as using strong passwords and biometric locks on their devices.
      <br />
      <br />
      <h2>User Rights</h2>
      <br />
      <br />
      Users have the right to access, correct, or delete their personal data. If
      you wish to exercise these rights, please contact us at the email address
      provided below.
      <br />
      <br />
      <h2>Children's Privacy</h2>
      <br />
      <br />
      We do not knowingly collect or use personal information from children
      under 13. If we learn that we have collected personal information from a
      child under 13, we will take steps to delete the information as soon as
      possible.
      <br />
      <br />
      <h2>Changes to the privacy policy</h2>
      <br />
      <br />
      IdeaList reserves the right to modify this privacy policy at any time. We
      will inform you of any significant changes by updating the last revision
      date of this policy. It is the user's responsibility to regularly consult
      this privacy policy to be informed of any possible changes.
      <br />
      <br />
      <h2>Contact</h2>
      <br />
      <br />
      If you have any questions or concerns regarding this privacy policy,
      please do not hesitate to contact us at the following email address:{" "}
      <a href="mailto:contact@charles-zimmerlin.com">
        contact@charles-zimmerlin.com
      </a>
    </div>
  );
}

export default ConfidentialityIdeaList;
