import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Confidentiality from "./Confidentiality";
import ConfidentialityKanban from "./ConfidentialityKanban";
import Layout from "./Layout";
import IdeaList from "./IdeaList";
import ConfidentialityIdeaList from "./ConfidentialityIdeaList";
import Kanban from "./Kanban";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<App />} />
          <Route path="confidentiality" element={<Confidentiality />} />
          <Route path="idealist" element={<IdeaList />} />
          <Route path="kanban" element={<Kanban />} />
          <Route
            path="confidentialityKanban"
            element={<ConfidentialityKanban />}
          />
          <Route
            path="confidentiality-idealist"
            element={<ConfidentialityIdeaList />}
          />
          <Route path="*" element={<App />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
