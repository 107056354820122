import "./App.css";
import { Link } from "react-router-dom";

function Kanban() {
  return (
    <div className="App">
      <div className="top">
        <div className="content">
          <span className="title">Kanban - Project Management</span>
          <p className="desc" style={{ textAlign: "center" }}>
            Easiest way to manage your projects. Kanban is a visual project
            management tool that helps you to manage your tasks and projects
            efficiently.
          </p>
          <div className="btns" style={{ color: "white" }}>
            Support mail:{" "}
            <a href="mailto:contact@charles-zimmerlin.com">
              contact@charles-zimmerlin.com
            </a>
            {/* <a
              href="https://apps.apple.com/us/app/dotoday-simple-to-do/id6458744179?itsct=apps_box_badge&amp;itscg=30200"
              className="appst"
            >
              <img
                src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/white/en-us?size=150x50&amp;releaseDate=1693526400"
                alt="Download on the App Store"
                className="appstImg"
                width={150}
                height={50}
              />
            </a> */}
          </div>
        </div>{" "}
      </div>
      <footer>
        <Link className="footerA" to="/confidentialityKanban">
          Privacy Policy
        </Link>
        <span className="footerText">Copyright © 2024</span>
        <a
          className="footerA"
          href="https://www.apple.com/legal/internet-services/itunes/dev/stdeula/"
        >
          EULA
        </a>
      </footer>
    </div>
  );
}

export default Kanban;
