import "./App.css";
import mockup from "./assets/mockup.webp";
import logo from "./assets/LogoIntro.webp";

function App() {
  return (
    <div className="App">
      <div className="top">
        <div className="logo"></div>
        <div className="content">
          <span className="title">
            {" "}
            <img
              className="logoImg"
              src={logo}
              alt="logo"
              width={64}
              height={64}
            />{" "}
            Do Today
          </span>
          <p className="desc">
            Do Today is a simple to-do list app that helps you organize your
            day. It is free, supports +20 languages and is available on the App
            Store !
          </p>
          <div className="btns">
            <a
              href="https://apps.apple.com/us/app/dotoday-simple-to-do/id6458744179?itsct=apps_box_badge&amp;itscg=30200"
              className="appst"
            >
              <img
                src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/white/en-us?size=150x50&amp;releaseDate=1693526400"
                alt="Download on the App Store"
                className="appstImg"
                width={150}
                height={50}
              />
            </a>
          </div>
        </div>
        <div className="mockup">
          <img
            className="mockupImg"
            src={mockup}
            alt="mockup"
            width={500}
            height={500}
          />
        </div>
      </div>
      <footer>
        <a
          className="footerA"
          href="https://www.iubenda.com/privacy-policy/81592360"
        >
          Privacy Policy
        </a>
        <span className="footerText">Copyright © 2024 Do Today</span>
        <a
          className="footerA"
          href="https://www.iubenda.com/terms-and-conditions/81592360"
        >
          CGU
        </a>
      </footer>
    </div>
  );
}

export default App;
