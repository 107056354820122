import { Link } from "react-router-dom";
import "./Confidentiality.css";

function ConfidentialityKanban() {
  return (
    <div id="confidentiality">
      <Link to="/kanban">
        <span className="back"> Back to home</span>
      </Link>
      <h1>Kanban Privacy Policy</h1>
      <br />
      <br />
      <h2>Introduction</h2>
      <br />
      <br />
      The privacy of our users is of utmost importance to us. This privacy
      policy describes Kanban's practices regarding the collection, use, and
      protection of the personal data of our application's users.
      <br />
      <br />
      <h2>Storage and use of data</h2>
      <br />
      <br />
      Kanban is designed to store all data locally only on the user's device. No
      user data is transmitted, stored, or exploited on external servers or by
      third parties. All information entered into the application, including
      financial information and transactions, remains exclusively on the user's
      device.
      <br />
      <br />
      <h2>In-App Purchases</h2>
      <br />
      <br />
      If Kanban offers in-app purchases, the transactions are processed by the
      platform provider (e.g., Apple's App Store). We do not process, store, or
      have access to any payment information related to in-app purchases. For
      questions or concerns about in-app purchase transactions, please contact
      the platform provider directly.
      <br />
      <br />
      <h2>External connection</h2>
      <br />
      <br />
      Kanban synchronize your data with icloud. Therefore, no user data is
      shared with other services, partners, or third parties. The server and
      available data are hosted by Apple.
      <br />
      <br />
      <h2>Data security</h2>
      <br />
      <br />
      The data security of our users is essential. Kanban uses appropriate
      security measures to protect the data stored locally on the user's device.
      We recommend users to take additional precautions to protect their data,
      such as using strong passwords and biometric locks on their devices.
      <br />
      <br />
      <h2>User Rights</h2>
      <br />
      <br />
      Users have the right to access, correct, or delete their personal data. If
      you wish to exercise these rights, please contact us at the email address
      provided below.
      <br />
      <br />
      <h2>Children's Privacy</h2>
      <br />
      <br />
      We do not knowingly collect or use personal information from children
      under 13. If we learn that we have collected personal information from a
      child under 13, we will take steps to delete the information as soon as
      possible.
      <br />
      <br />
      <h2>Changes to the privacy policy</h2>
      <br />
      <br />
      Kanban reserves the right to modify this privacy policy at any time. We
      will inform you of any significant changes by updating the last revision
      date of this policy. It is the user's responsibility to regularly consult
      this privacy policy to be informed of any possible changes.
      <br />
      <br />
      <h2>Contact</h2>
      <br />
      <br />
      If you have any questions or concerns regarding this privacy policy,
      please do not hesitate to contact us at the following email address:{" "}
      <a href="mailto:contact@charles-zimmerlin.com">
        contact@charles-zimmerlin.com
      </a>
    </div>
  );
}

export default ConfidentialityKanban;
